import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import ProjectCard from "../cards/ProjectCard";
import { UserContext } from "../context/Context";
import BootstrapDialog from "../utils/BootstrapDialog";
import { DARKRED, NAVY } from "../utils/Constants";
import FileUploader from "../utils/FileUploader";
import { useQuery } from "react-query";
import { useEffect } from "react";
import imageService from "../../services/imageService";
import projectService from "../../services/projectService";

const variant = "outlined";

const Projects = () => {

    let { type } = useParams();
    const [projetcs, setProjects] = useState([]);
    const [openProjectDialog, setOpenProjectDialog] = useState(false);
    const admin = useContext(UserContext);

    const handleProjectDialogClose = () => {
        setOpenProjectDialog(false);
        setSelectedProject(null);
        setImages([]);
    };

    const handleFormSubmit = async(event)=>{
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const project = Object.fromEntries(formData.entries());
        if(images.length == 0){
            alert("You must select an image to create a project")
            return
        }
        var uploadedImage = await imageService.uploadImage('img', images[0]);
        if(!uploadedImage)
            return
        project.imageUrl = uploadedImage.imageUrl
        project.isLive = project.isLive == 'true' ? true:false;
        
        var postedProject = null;
        if(selectedProject == null){
            postedProject = await projectService.post(project)
            if((project.isLive == true && type == 'live') || (project.isLive == false && type == 'completed')){
                setProjects(([...projetcs, postedProject]))
            }
        }else{
            project.id = selectedProject.id
            await projectService.put(project)
            postedProject = project
            if((project.isLive == true && type == 'live') || (project.isLive == false && type == 'completed')){
                setProjects(prevProjects => {
                    const updatedProjects = prevProjects.map(project => {
                        if (project.id === postedProject.id) {
                            return postedProject;
                        }
                        return project;
                    });
                    return updatedProjects;
                });
            }
        }
        handleProjectDialogClose();
    }

    const confirmDelete = async ()=>{
        var response = prompt("Do you want to delete this project","yes");
        if ( !response  || response.toUpperCase()!="YES") {
            return;
        }
        await projectService.delete(selectedProject.id)
        setProjects(prevProjects => {
            return prevProjects.filter(project => project.id !== selectedProject.id);
        });
        handleProjectDialogClose();
    }


    useEffect(()=>{
        (async ()=>{
            setProjects(await projectService.getAll(type))
        })()
    },[type])

    const AddProject = () => {
        setOpenProjectDialog(true);
    }

    const [selectedProject, setSelectedProject] = useState(null);
    const [images, setImages] = useState([]);

    const handleProjectSelect = (project) => {
        setSelectedProject(project);
        //setImages([project.imageUrl]);
        if (admin) {
            setOpenProjectDialog(true);
        }
    }

    return (
        <Box sx={{ p: { xs: 2, md: 5 }}} width="100%">
            {
                admin && <Button variant="contained" color="error" onClick={AddProject} sx={{mb:2}}>Add Project</Button>
            }
            <Typography variant="h4" mb={2} color={DARKRED} sx={{ fontWeight: 'bold'}}>Lycan Constructions - Projects all over Tamilnadu</Typography>
            <Grid container spacing={{xs:3, md:6}} alignItems="center">
                {
                    projetcs.map((item, index) =>
                        <Grid key={index} item xs={12} md={4} alignContent="center">
                            <ProjectCard project={item} onClick={handleProjectSelect} />
                        </Grid>
                    )
                }
            </Grid>
            <BootstrapDialog
                onClose={handleProjectDialogClose}
                open={openProjectDialog}
                id="projectDialog"
                scroll="body"
                PaperProps={{
                    component: 'form',
                    onSubmit: handleFormSubmit,
                }}
                sx={{'& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    borderColor: NAVY,
                                },
                            },
                            '& label.Mui-focused': { color: NAVY, },}}
            >
                <DialogTitle sx={{ m: 0, p: 2, backgroundColor: NAVY, color: "white" }} id="customized-dialog-title">
                    Add Project
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleProjectDialogClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: "white"
                    }}
                >
                    <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
                </IconButton>
                <DialogContent dividers>

                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="projectName"
                        label="Client Name"
                        fullWidth
                        variant={variant}
                        defaultValue={selectedProject ? selectedProject.projectName : ''}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="desc"
                        name="projectTitle"
                        label="Project Description"
                        fullWidth
                        variant={variant}
                        defaultValue={selectedProject ? selectedProject.projectTitle : ''}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="projectType"
                        name="projectType"
                        label="Project Type"
                        fullWidth
                        variant={variant}
                        defaultValue={selectedProject ? selectedProject.projectType : ''}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="salableArea"
                        name="salableArea"
                        label="Saleable Area"
                        fullWidth
                        variant={variant}
                        defaultValue={selectedProject ? selectedProject.salableArea : ''}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="approval"
                        name="approval"
                        label="Approval"
                        fullWidth
                        variant={variant}
                        defaultValue={selectedProject ? selectedProject.approval : ''}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="structure"
                        name="structure"
                        label="Structure"
                        fullWidth
                        variant={variant}
                        defaultValue={selectedProject ? selectedProject.structure : ''}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="possession"
                        name="possession"
                        label="Possession"
                        fullWidth
                        variant={variant}
                        defaultValue={selectedProject ? selectedProject.possession : ''}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="isLive"
                        name="isLive"
                        label="Project Status"
                        fullWidth
                        variant={variant}
                        select
                        defaultValue={selectedProject ? selectedProject.isLive : "true"}
                    >
                        <MenuItem value="true"> Live </MenuItem>
                        <MenuItem value="false"> Completed </MenuItem>
                    </TextField>
                    <FileUploader required images={images} setImages={setImages} variant={variant}/>
                </DialogContent>
                <DialogActions>
                    { selectedProject && <Button onClick={confirmDelete} sx={{color:NAVY}} color='error'>Delete</Button>}
                    <Button onClick={handleProjectDialogClose} sx={{color:NAVY}}>Cancel</Button>
                    <Button type="submit" sx={{color:NAVY}}>Submit</Button>
                </DialogActions>
            </BootstrapDialog>
        </Box>
    );
}


export default Projects;
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './components/Layout';
import NoPage from './components/pages/NoPage';
import Home from './components/pages/Home';
import Projects from "./components/pages/Projects";
import Vision from "./components/pages/Vision";
import Leadership from "./components/pages/Leadership";
import Group from "./components/pages/Group";
import Careers from "./components/pages/Careers";
import Privacy from "./components/pages/Privacy";
import { ADMIN_PATH } from "./components/utils/Constants";
import Collaborators from "./components/pages/Collaborators";
import Gallery from "./components/pages/Gallery";
import ScrollToTop from "./components/utils/ScrollToTop"
import authService from "./services/authService";
import { useEffect, useState } from "react";

function App() {
  
  const [mode, setMode] = useState(null);

  useEffect(() => {
    const adminAuth = async() => {
      if(window.location.pathname === ADMIN_PATH){
        let pass = prompt("Admin password");
        if(!pass){
          return
        }
        let resp = await authService.checkAdminAuth({"key": pass});
        if(resp)
          setMode('admin');
        console.log('mode: ',mode);
      }
    }
  
    adminAuth();

    return () => {
      setMode(null);
    };
  }, []);

  

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout mode={mode}/>}>
          <Route index element={<Home />} />
          <Route path="/projects/:type" element={<Projects />} />
          <Route path="/projects/:type" element={<Projects />} />
          <Route path="/vision" element={<Vision />} />
          <Route path="/leadership" element={<Leadership />} />
          <Route path="/group" element={<Group />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/collaborations" element={<Collaborators />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path={ADMIN_PATH} element={<Home />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

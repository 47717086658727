import {  Box, Card, CardContent, CardMedia, Grid,  Typography } from "@mui/material";
import { DARKRED, NAVY } from "../utils/Constants";


const HomeCard = ({ project }) => {

    return (
        <Card elevation={0} sx={{ height: "auto", width: "100%", mb:4, borderRight:{xs:'none',md:"3px solid #BD0000"} }}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <CardMedia
                        component="img"
                        sx={{height:{xs:300, md:350}}}
                        image={project.imageUrl}
                        alt={project.projectName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CardContent>
                        <Typography mt={1} mb={2} variant="h5" sx={{ fontWeight: 'bold', color: DARKRED }}>
                            {project.projectName}
                        </Typography>
                        <Typography mb={2} variant="h6" fontStyle="italic" sx={{ color: DARKRED }}>
                            {project.projectTitle}
                        </Typography>
                        <Grid container rowSpacing={1}>
                            <Grid item xs={4}>
                                <Typography variant="body1" sx={{ fontFamily: ['cursive'], fontWeight: 'bold', color:NAVY }}> Project Type</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" sx={{color: NAVY}}> : {project.projectType}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" sx={{ fontFamily: ['cursive'], fontWeight: 'bold', color:NAVY }}> Saleable Area</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" sx={{color: NAVY}}> : {project.salableArea}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" sx={{ fontFamily: ['cursive'], fontWeight: 'bold', color:NAVY }}> Approval</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" sx={{color: NAVY}}> : {project.approval}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" sx={{ fontFamily: ['cursive'], fontWeight: 'bold', color:NAVY }}> Structure</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" sx={{color: NAVY}}> : {project.structure}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" sx={{ fontFamily: ['cursive'], fontWeight: 'bold', color:NAVY }}> Possession</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" sx={{color: NAVY}}> : {project.possession}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Grid>

            </Grid>
        </Card>
    );
}

export default HomeCard;
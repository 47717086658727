import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, List, ListItem, ListItemButton, ListItemText, Paper} from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";

export const ListItemWithChildren = ({ label, data, close, variant }) => {

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
      };

    const handleChildBtnClick = () => {
        setOpen(!open);
        close();
    };

    return (
        <>
            <ListItem disablePadding sx={{mt:1}}>
                <ListItemButton onClick={handleClick} sx={{ p: 0 }}>
                    <ListItemText primary={label} primaryTypographyProps={{variant: variant}}/>
                    <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown}/>
                </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component={Paper} elevation={3} disablePadding>
                {
                    data.map((item,index)=>
                    <ListItemButton key={index} sx={{ pl: 3, py:0 }} component={Link} to={item.value} onClick={handleChildBtnClick}>
                        <ListItemText primary={item.label} primaryTypographyProps={{variant: variant}}/>
                    </ListItemButton>
                    )
                }
                
                </List>
            </Collapse>
        </>
    );
}

import { faReadme } from "@fortawesome/free-brands-svg-icons";
import { faCompass, faHandshake, faHourglassHalf, faLayerGroup, faMedal, faScaleBalanced, faSignal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Card, MenuItem, Paper, Stack, TextField, Typography } from "@mui/material";
import emailService from "../../services/emailService";
import { DARKRED, NAVY } from "../utils/Constants";

const variant = "outlined";

const Collaborators = () => {

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        if (!validateEmail(formJson.Email)) {
            alert('Invalid email address');
            return;
        }

        if (!validateNumber(formJson.Mobile)) {
            alert('Invalid mobile number');
            return;
        }

        let response = await emailService.sendMail({ "subject": "New Partner Request from " + formJson.Name, "body": formJson });
        console.log("Collaboration Request status:", response.status);
        if (response.status === "success") {
            clearForm();
            alert("Request Submitted successfully");
        } else {
            alert("Request Failed. Try after sometime.")
        }
    };

    const clearForm = () => {
        ["name", "type", "email", "mobile", "address", "expected", "message", "property", "land", "road", "direction"].forEach(id => {
            document.getElementById(id).value = '';
        });
    }


    const validateEmail = (mail) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(mail);
    };

    const validateNumber = (number) => {
        const mobileRegex = /^[0-9]{10,}$/;
        return mobileRegex.test(number);
    };

    return (
        <div>
            <Box width="100%">
                <Typography variant="h4" color={DARKRED} textAlign="center" my={5} mx={2} fontWeight="bold">Why do Collaborate with Lycan Constructions?</Typography>
                <Stack direction={{ xs: 'column', md: 'row' }} mb={5} sx={{ px: { md: 7 }, mx: { xs: 2 } }} justifyContent="center" spacing={2}>
                    <Paper sx={{ px: 2, py: 1, width: { md: 250 }, height: 200 }} elevation={5}>
                        <Stack direction="row" alignItems="center" justifyContent="space-around" height="25%" spacing={1}>
                            <FontAwesomeIcon icon={faHourglassHalf} size="xl" color={DARKRED} />
                            <Typography width="80%" lineHeight="1.2">Timely Delivery</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-around" height="25%" spacing={1}>
                            <FontAwesomeIcon icon={faCompass} size="xl" color={DARKRED} />
                            <Typography width="80%" lineHeight="1.2">Vaastu Compliance</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-around" height="25%" spacing={1}>
                            <FontAwesomeIcon icon={faHandshake} size="xl" color={DARKRED} />
                            <Typography width="80%" lineHeight="1.2">Loyal, Honest & Integrity</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-around" height="25%" spacing={1}>
                            <FontAwesomeIcon icon={faScaleBalanced} size="xl" color={DARKRED} />
                            <Typography width="80%" lineHeight="1.2">Expected Ratio can be a drop list option</Typography>
                        </Stack>
                    </Paper>
                    <Stack px={2} sx={{ width: { md: "60%" } }}>
                        <Typography mb={2} textAlign="justify">True service entails more than monetary transactions; it requires sincerity and integrity, qualities that cannot be purchased or quantified.</Typography>
                        <Typography textAlign="justify">Lycan Constructions offers extensive expertise in development, architecture, design, engineering, construction, sales, and real estate investments. Our holistic approach and outstanding execution attract Land Owners for Joint development projects. Their keen interest simplifies fund-raising and property value enhancement seamlessly.</Typography>
                    </Stack>
                    <Paper sx={{ px: 2, py: 1, width: { md: 250 }, height: 200 }} elevation={5}>
                        <Stack direction="row" alignItems="center" justifyContent="space-around" height="25%" spacing={1}>
                            <FontAwesomeIcon icon={faMedal} size="xl" color={DARKRED} />
                            <Typography width="80%" lineHeight="1.2">Assured Quality</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-around" height="25%" spacing={1}>
                            <FontAwesomeIcon icon={faSignal} size="xl" color={DARKRED} />
                            <Typography width="80%" lineHeight="1.2">Higher Sale & Rental Values</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-around" height="25%" spacing={1}>
                            <FontAwesomeIcon icon={faLayerGroup} size="xl" color={DARKRED} />
                            <Typography width="80%" lineHeight="1.2">Functionality & Elegant designs</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-around" height="25%" spacing={1}>
                            <FontAwesomeIcon icon={faReadme} size="xl" color={DARKRED} />
                            <Typography width="80%" lineHeight="1.2">Clear Documentation</Typography>
                        </Stack>
                    </Paper>
                </Stack>
                <Stack sx={{ width: "100%", backgroundColor: "white" }} >
                    <Typography textAlign="center" mt={3} variant="h4" fontWeight="bold" color={DARKRED}>Join as Collaborators</Typography>
                    <Typography textAlign="center" fontWeight="bold" variant="subtitle2" my={2}>Please fill out the form below, and we will get back to you shortly to explore potential collaboration opportunities.</Typography>
                    <Typography textAlign="center"> For more details, Please contact – <Typography fontWeight="bold" color={DARKRED} display="inline-block">+91 91766 52155</Typography></Typography>
                    <Typography textAlign="center"> (Lycan Constructions assures the confidentiality of all information shared with us.)</Typography>
                    <Card
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '96%', backgroundColor: "white" },
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    borderColor: NAVY,
                                },
                            },
                            '& label.Mui-focused': { color: NAVY, },
                            ml: { md: "18%" },
                            width: { md: "64%" },
                            my: { md: 5 },
                            m: { xs: 2 },
                            backgroundColor: "#f7f7f7"
                        }}
                        elevation={5}
                    >
                        <Box component="form"
                            autoComplete="off"
                            m={{ md: 3, xs: 1 }}
                            onSubmit={handleFormSubmit}
                        >
                            <Typography textAlign="center" gutterBottom variant="h6" fontWeight="bold">Contact Details</Typography>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="name"
                                name="Name"
                                label="Full Name"
                                fullWidth
                                variant={variant}
                            />
                            <TextField
                                required
                                margin="dense"
                                id="type"
                                name="Type"
                                label="Collaborator Type"
                                select
                                fullWidth
                                variant={variant}
                            >
                                <MenuItem value="Land Owner">Land Owner</MenuItem>
                                <MenuItem value="Mediator">Mediator</MenuItem>
                            </TextField>
                            <TextField
                                required
                                margin="dense"
                                id="email"
                                name="Email"
                                label="Email Address"
                                type="email"
                                fullWidth
                                variant={variant}
                            />
                            <TextField
                                required
                                margin="dense"
                                id="mobile"
                                name="Mobile"
                                label="Mobile Number"
                                type="tel"
                                fullWidth
                                variant={variant}
                            />
                            <TextField
                                required
                                margin="dense"
                                id="address"
                                name="Address"
                                label="Address"
                                multiline
                                rows={4}
                                placeholder="Address"
                                fullWidth
                                variant={variant}
                            />
                            <TextField
                                required
                                margin="dense"
                                id="expected"
                                name="Expected Ratio"
                                label="Expected Ratio"
                                fullWidth
                                variant={variant}
                            />
                            <TextField
                                margin="dense"
                                id="message"
                                label="Message"
                                name="Message"
                                multiline
                                rows={4}
                                placeholder="Message"
                                fullWidth
                                variant={variant}
                            />
                            <Typography textAlign="center" my={2} variant="h6" fontWeight="bold">Property Details</Typography>
                            <TextField
                                required
                                margin="dense"
                                id="property"
                                name="Property Address"
                                label="Property Detailed Address"
                                fullWidth
                                variant={variant}
                            />
                            <TextField
                                required
                                margin="dense"
                                id="land"
                                name="Land Area(sq.ft)"
                                label="Land Area in sq.ft"
                                fullWidth
                                variant={variant}
                            />
                            <TextField
                                margin="dense"
                                id="road"
                                name="Road Width"
                                label="Road Width"
                                fullWidth
                                variant={variant}
                            />
                            <TextField
                                margin="dense"
                                id="direction"
                                name="Road Facing Direction"
                                label="Road Facing Direction"
                                fullWidth
                                variant={variant}
                            />
                            <Button sx={{ ml: "43%", my: 2 }} type="submit" variant="contained" color="error">Submit</Button>
                        </Box>
                    </Card>
                </Stack>
            </Box>
        </div>
    );
}

export default Collaborators;
import { Paper, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import LycanCareers from "../../assets/lycan_careers.jpg";
import { DARKRED } from "../utils/Constants";

const Careers = () => {
    return(
        <Box component={Paper} sx={{m:{xs: 2, md: 5}}} width="100%">
            <Typography variant="h5" m={2}  sx={{ fontWeight: "bold", textTransform: "capitalize", color: DARKRED }}>Lycan Constructions - Careers</Typography>
            <Box m={2}>
                <img width="100%" alt="Careers" src={LycanCareers}/>
            </Box>
        </Box>
    );
}

export default Careers;
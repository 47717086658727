import axios from "axios";
import { baseURL } from "../components/utils/Constants";

class AuthClient{

    checkAdminAuth = async (request) => {
        try {
            var response = await axios.post(`${baseURL}/checkAuth`, request);
            return response.status === 200;
        } catch (error) {
            console.error("Error in get:", error);
            return false;
        }
    }
}

export default new AuthClient();
import { Box, Container, Divider, Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import LycanCon from "../../assets/lycan_group.jpg";
import LycanDecors from "../../assets/lycan_decors.jpg";
import { DARKRED, NAVY } from "../utils/Constants";

const Group = () => {

    const isMobile = useMediaQuery('(max-width:600px)');

    return(
        <Box component={Paper} sx={{m:{xs: 2, md: 5}}} width="100%">
            <Stack direction={{ xs: 'column', md: 'row' }}>
                <Container sx={{ my:2}} >
                    <img
                        width={isMobile ? '100%' : '500px'}
                        alt="Lycan Constructions"
                        src={LycanCon}
                        />
                </Container>
                <Box px={2} pt={2}>
                    <Typography variant="h5" sx={{fontWeight:"bold", textTransform: "uppercase", fontFamily:["Rockwell"], color:DARKRED}}>Lycan Constructions</Typography>
                    <Typography variant="body2" mt={2} sx={{fontFamily:["Rockwell"], textAlign:'justify', color:NAVY }} >
                    Established in the year 2022 , Lycan Constructions is one of the most trusted and reputed Constructions in Tamilnadu. Catering to a wide array of home segments ranging from compact homes to plush villas, well-developed plots to commercial complexes, affordable to luxury projects.
                    </Typography>
                    <Typography variant="body2" mt={2} sx={{fontFamily:["Rockwell"], textAlign:'justify', color:NAVY }} >
                    Over the last two years, the Company is armed with metaphors that describe the robust relationship with its clients, sustainable quality standards, reliability, and impregnable safety standards thus delivering homes to happy Customers.
                    </Typography>
                    <Typography variant="body2" mt={2} sx={{fontFamily:["Rockwell"], textAlign:'justify', color:NAVY }} >
                    Delivering superior quality homes on-time every time made us to backward integrate into manufacturing ready-mix concrete, fly ash bricks, paver blocks, doors & windows, ceramics.
                    </Typography>
                    <Typography variant="body2" mt={2} sx={{fontFamily:["Rockwell"], textAlign:'justify', color:NAVY }} >
                    Adopting new-age technology, modern machinery, innovative techniques and the quest for growth are the inimitable hallmarks of the Company.
                    </Typography>
                </Box>
            </Stack>
            <Divider sx={{my:2}} variant="middle"/>
            <Stack direction={{ xs: 'column', md: 'row' }} mb={2}>
                <Container sx={{ my:2}} >
                    <img width="100%" alt="vision" src={LycanDecors} style={{marginTop: 5}}/>
                </Container>
                <Box px={2} pt={2}>
                    <Typography variant="h6" sx={{fontWeight:"bold", textTransform: "uppercase", fontFamily:["Rockwell"], color:DARKRED}}>Lycan Plate Decors</Typography>
                    <Typography variant="body2" mt={2} sx={{fontFamily:["Rockwell"], textAlign:'justify', color:NAVY }} >
                    Established in 2020 with a passion for innovative designs, ideas and a years of experience, we at creative Plate Decor has made a mark in the industry through its wide range articles.We make plate decorations for wedding, engagement, baby shower ,saree wearing function and all other functions in and around Tamilnadu
                    </Typography>
                </Box>
            </Stack>
        </Box>
    );
}

export default Group;
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faSquareFacebook, faLinkedin, faSquareYoutube, faSquareXTwitter, faWhatsappSquare } from '@fortawesome/free-brands-svg-icons';


export const baseURL = "https://rmj4ydrcdg.execute-api.ap-south-1.amazonaws.com";
export const s3URL = "https://lycan-constructions-bucket-prod.s3.ap-south-1.amazonaws.com";


export const ADMIN_PATH = "/lycanadmin";

export const NAVY = "#082042";
export const WHITE = "#ffffff";
export const DARKRED = "#BD0000";

export const pages = [{ label: "Home", key: "home", value: "/" }, { label: "Live Projects", key: "live", value: "/projects/live" }, { label: "Completed Projects", key: "completed", value: "/projects/completed" }, { label: "Gallery", key: "gallery", value: "/gallery" }];
export const more = [{ label: "Careers", key: "careers", value: "/careers" }, { label: "Partner with us", key: "collaborations", value: "/collaborations" }, { label: "Privacy Policy", key: "privacy", value: "/privacy-policy" }];
export const aboutList = [{ label: "Vision & Mission", key: "vision", value: "/vision" }, { label: "Leadership", key: "leadership", value: "/leadership" }, { label: "Group of Companies", key: "group", value: "/group" }];
export const contacts = [{label:"Location", key: "location", color:DARKRED, value: "https://maps.app.goo.gl/gpsbKfbyW66JhVW69", icon: faLocationDot}, {label:"Whatsapp", key: "whatsapp", color:"#25D366", value: "https://api.whatsapp.com/send?phone=+919176652155&text=Hi,%20I%27m%20interested%20in%20Lycan%20Constructions%20Project.%20Please%20get%20in%20touch%20with%20me.", icon: faWhatsappSquare}];
export const social = [{label:"Visit Instagram Page", key: "instagram", color: "#C13584", value: "https://www.instagram.com/lycan_constructions26", icon: faInstagram}, 
                        {label:"Visit Facebook Page", key: "facebook", color: "#1877F2", value: "https://www.facebook.com/profile.php?id=61555781229407", icon: faSquareFacebook}, 
                        {label:"Visit Linkedin Page", key: "linkedin", color: "#0A66C2", value: "https://www.linkedin.com/in/lycan-constructions-6048122ab/", icon: faLinkedin}, 
                        {label:"Visit Youtube Page", key: "youtube", color: "#FF0000", value: "https://www.youtube.com/@LycanConstructions", icon: faSquareYoutube}, 
                        {label:"Visit X Page", key: "twitter", color: NAVY, value: "https://x.com/LycanConst4489", icon: faSquareXTwitter}];

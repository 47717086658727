import { Outlet } from "react-router-dom";
import { UserContext } from "./context/Context";
import Footer from "./Footer";
import Header from "./Header";
import SideDrawer from "./SideDrawer";

const Layout = (props) => {

    let admin = props?.mode === "admin" ? true : false;

    return(
        <UserContext.Provider value={admin}>
            <Header />
            <div style={{display:"flex"}}>
                <SideDrawer />
                <Outlet />
            </div>
            <Footer />
        </UserContext.Provider>
    );
}

export default Layout;
import { Card, CardActionArea, CardContent, CardMedia, Grid, styled, Typography } from "@mui/material";
import { NAVY } from "../utils/Constants";


const StyledCard = styled(Card)(({ theme }) => ({
    transition: "transform 0.15s ease-in-out",
    "&:hover": { transform: "scale3d(1.02, 1.02, 1)" },
  }))


const ProjectCard = ({project, onClick}) => {

    const handleClick = (e) => {
        e.preventDefault();
        onClick(project)
    }

    return (
            project ?
        <StyledCard sx={{ width: "100%", height: "auto" }} onClick={handleClick}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="200"
                    image={project.imageUrl}
                    alt={project.projectName}
                />
                <CardContent>
                    <Typography mb={2} variant="body1" fontStyle="italic" sx={{fontWeight: 'bold', color:NAVY}}>
                        {project.projectTitle}
                    </Typography>
                    <Grid container>
                        <Grid item xs={4}>
                            <Typography variant="body2" sx={{fontFamily: ['cursive'], fontWeight: 'bold', color:NAVY}}> Client Name</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2" sx={{color: NAVY}}> : {project.projectName}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body2" sx={{fontFamily: ['cursive'], fontWeight: 'bold', color:NAVY}}> Project Type</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2" sx={{color: NAVY}}> : {project.projectType}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body2" sx={{fontFamily: ['cursive'], fontWeight: 'bold', color:NAVY}}> Saleable Area</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2" sx={{color: NAVY}}> : {project.salableArea}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body2" sx={{fontFamily: ['cursive'], fontWeight: 'bold', color:NAVY}}> Approval</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2" sx={{color: NAVY}}> : {project.approval}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body2" sx={{fontFamily: ['cursive'], fontWeight: 'bold', color:NAVY}}> Structure</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2" sx={{color: NAVY}}> : {project.structure}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body2" sx={{fontFamily: ['cursive'], fontWeight: 'bold', color:NAVY}}> Possession</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2" sx={{color: NAVY}}> : {project.possession}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </StyledCard>
        :
        <StyledCard sx={{ width: 350, height: 400 }} />
    );
}

export default ProjectCard;
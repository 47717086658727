import { Button, styled } from "@mui/material";
import { DARKRED, NAVY } from "./Constants";

const CustomButton = styled(Button)(({ theme }) => ({
    color: NAVY,
    ':hover': {
      color: DARKRED
    },
  }));

export default CustomButton;
  
import axios from "axios";
import { baseURL } from "../components/utils/Constants";
const resourceURL = "project"

class ProjectClient{

    get = async (id) => {
        try {
            var response = await axios.get(`${baseURL}/${resourceURL}/${id}`);
            return response.data;
        } catch (error) {
            console.error("Error in get:", error);
            throw error;
        }
    }

    delete = async (id) => {
        try {
            var response = await axios.delete(`${baseURL}/${resourceURL}/${id}`);
            return response.data;
        } catch (error) {
            console.error("Error in get:", error);
            throw error;
        }
    }

    post = async (project) => {
        try {
            var response = await axios.post(`${baseURL}/${resourceURL}`, project);
            return response.data;
        } catch (error) {
            console.error("Error in get:", error);
            throw error;
        }
    }

    put = async (project) => {
        try {
            var response = await axios.put(`${baseURL}/${resourceURL}`, project);
            return response.data;
        } catch (error) {
            console.error("Error in get:", error);
            throw error;
        }
    }
    
    getAll = async (type=null) => {
        return new Promise(async (resolve, reject)=>{
            try {
                var response = await axios.get(`${baseURL}/${resourceURL}`);
                if(type != null){
                    if(type == 'live'){
                        response.data = response.data.filter(project => project.isLive == true)
                    }else{
                        response.data = response.data.filter(project => project.isLive == false)
                    }
                }
                resolve(response.data);
            } catch (error) {
                console.error("Error in getAll:", error);
                reject(error);
            }
        })
    }
}


export default new ProjectClient();

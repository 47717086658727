import axios from "axios";
import { baseURL } from "../components/utils/Constants";
const resourceURL = "user"

class UserClient{
    put = async (req) => {
        try {
            var response = await axios.put(`${baseURL}/${resourceURL}`, req);
            return response.data;
        } catch (error) {
            console.error("Error in get:", error);
            throw error;
        }
    }
    
    getAll = async (type=null) => {
        return new Promise(async (resolve, reject)=>{
            try {
                var response = await axios.get(`${baseURL}/${resourceURL}`);
                resolve(response.data);
            } catch (error) {
                console.error("Error in getAll:", error);
                reject(error);
            }
        })
    }
}

export default new UserClient();

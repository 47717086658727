import { Box, Container, Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import { DARKRED, NAVY } from "../utils/Constants";
import Sripathi from "../../assets/sripathi.jpeg";

const Leadership = () => {

    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <Box component={Paper} sx={{ m: { xs: 2, md: 5 } }} width="100%">
            <Stack direction={{ xs: 'column', md: 'row' }} mb={2}>
                <Container sx={{ my:2}}>
                    <img
                        width={isMobile ? '100%' : '400px'}
                        alt="lycan"
                        src={Sripathi}

                    />
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold", fontFamily: ["Rockwell"], color: DARKRED }} my={1}>Mr. V. Sripathi, Founder & Chairman</Typography>
                </Container>
                <Box px={2}>
                    <Typography variant="h5" mt={2} sx={{ fontWeight: "bold", textTransform: "uppercase", fontFamily: ["Rockwell"], color: DARKRED }}>Lycan Constructions - Leadership</Typography>
                    <Typography variant="body2" mt={2} sx={{ fontFamily: ["Rockwell"], textAlign: 'justify', color: NAVY }}>
                        Mr. V. Sripathi is a first-generation entrepreneur, a visionary and Founder of Lycan Constructions. An ambitious Civil Engineering graduate from Anna University with a vision of delivering quality housing. His immense passion for creating world-class living spaces for all, saw the birth of Lycan Constructions - a brand that today is at the heart of the Housing sector in Tamil Nadu. He is well experienced and has been successfully leading the construction business.
                    </Typography>
                    <Typography variant="body2" mt={2} sx={{ fontFamily: ["Rockwell"], textAlign: 'justify', color: NAVY }}>
                        Lycan Constructions is known for trust and honesty, and we takes a step further in ensuring happy home buying experience at all customer touch-points.
                    </Typography>
                    <Typography variant="body2" mt={2} sx={{ fontFamily: ["Rockwell"], textAlign: 'justify', color: NAVY }}>
                        He has a greater vision for the Company to foray into diverse fields such as integrated community townships, commercial buildings, and warehousing.
                    </Typography>
                </Box>

            </Stack>
        </Box>
    );
}

export default Leadership;
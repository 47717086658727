import { Box, Typography, useMediaQuery, useTheme, Button } from "@mui/material";
import { useEffect, useState } from "react";
import HomeCard from "../cards/HomeCard";
import { DARKRED } from "../utils/Constants";
import projectService from "../../services/projectService";
import home1 from "../../assets/home1.jpg";
import home2 from "../../assets/home2.jpg";
import home3 from "../../assets/home3.jpeg";
import { Link } from "react-router-dom";

const carouselImages = [home1, home2, home3];

const Home = () => {

    const [imageIndex, setImageIndex] = useState(0);
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const images = carouselImages;
    const [projects, setProjects] = useState([])

    useEffect(() => {
        const interval = setInterval(() => {
            setImageIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 4000);
        
        (async()=>{
            setProjects(await projectService.getAll('live')) 
        })()
        
        return () => clearInterval(interval);
    }, []);

    const handleDotClick = (index) => {
        setImageIndex(index);
    };

    return (
        <Box width="100%">
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                sx={{ height: { xs: "300px", md: "100vh" }, position: 'relative' }}
            >
                { imageIndex===1 ? <Box width="100%" height="100%" sx={{ position:'absolute', zIndex:1}} display="flex" alignItems="center" justifyContent="center"><Button className="zoom-in-button" color="error" variant="contained" component={Link} to="/collaborations">Joint Ventures</Button></Box> : <></>}
                <img
                    src={images[imageIndex]}
                    alt="Slideshow"
                    style={{ width: '100%', height: '100%', background: "#000" }}
                />
                {/* <Box sx={{ position: 'absolute',top:{xs:"140px",md:'200px'},color:"#ffffff", borderLeft: '2px solid #d32f2f', px: 2, py: 1, zIndex:2,mb:2 }}>
                     <Typography variant="h6" sx={{pl:0.3}} gutterBottom>
                        Ready to
                    </Typography> 
                    <Typography variant="h3" gutterBottom>
                        Build Your
                    </Typography>
                        <Typography sx={{ color: 'white', backgroundColor:'#d32f2f', px:2, m:0 }} variant="h2" gutterBottom>
                            Dream Home
                        </Typography>
                </Box> */}
                <div style={{ position: 'absolute', bottom: 20, left: '52%', transform: 'translateX(-50%)', display: 'flex' }}>
                    {images.map((_, index) => (
                        <div key={index} style={{ width: 10, height: 10, borderRadius: '50%', background: index === imageIndex ? 'white' : 'gray', margin: 5, cursor: 'pointer' }} onClick={() => handleDotClick(index)}></div>
                    ))}
                </div>
            </Box>

            {/* <Box class="Services" sx={{display:"flex", alignItems:'center',flexDirection:'column', width:'100%', mt:4}}>
                <Typography variant="body1" sx={{textAlign:'center', width:'100%', color:'#BD0000'}} >One Stop Solution for all your Needs</Typography>
                <Typography variant="h4" sx={{textAlign:'center', width:'100%', color:NAVY,mt:1}} >Our Special Services</Typography>
            
                <Grid container class='ServiceGrid'>

                </Grid>
            </Box> */}

            <Typography variant="h4" sx={{ ml: { md: 5, xs: 2 }, mt: { md: 5, xs: 2 }, textAlign:'center' }} color={DARKRED}>TOP LIVE PROJECTS FROM TAMILNADU</Typography>
            <Box sx={{ px: { xs: 2, md: 10 }, pt: 2, mt:1 }}>
                {projects.map((project, index)=> <HomeCard key={index} project={project}/>)}
            </Box>
        </Box>
    );

}

export default Home;
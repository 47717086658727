import { Box, Typography } from "@mui/material";

const NoPage = () => {
    return(
        <Box sx={{ p: { xs: 2, md: 5 }}} width="100%">
            <Typography variant="h5">404 Not Found</Typography>
        </Box>
    );
}

export default NoPage;

import { Box, Button, Tooltip, Popover, Tab} from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { DARKRED, NAVY } from './Constants';
import CustomButton from './CustomButton';


const DropdownTab = ({ label, value, data, onChange, currentTab }) => {

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (e) => {
    setAnchorEl(null);
    let hit = data.find(i => i.key === e.target.id);
    if (hit)
      onChange(e, value);

  };

  return (
    <div>
      <Tab
        className={(currentTab === value) ? 'fullOpacity' : ''}
        label={<span style={{ color: (currentTab === value) ? DARKRED : NAVY }}>{label}</span>}
        value={value}
        component={Button}
        onClick={handlePopoverOpen}
        icon={<FontAwesomeIcon icon={faCaretDown} />}
        iconPosition="end" />
      <Popover
        id="mouse-over-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
      >
        <Box m={2} sx={{ display: 'flex', flexDirection: "column", alignItems: "start" }}>
          {
            data.map((item) =>
              <Tooltip title={item.label} placement='left-end' key={item.key}>
                <CustomButton id={item.key} component={Link} to={item.value} onClick={handlePopoverClose}> {item.label} </CustomButton>
              </Tooltip>
            )
          }
        </Box>
      </Popover>
    </div>
  );
}

export default DropdownTab;
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { aboutList, more, pages } from "./Constants";
import { ListItemWithChildren } from "./ListView";

const subMenuList = [{ label: "About", data: aboutList }, { label: "More", data: more }];
const drawerWidth = 240;
const typographyVariant = "button";

const MobNavMenu = () => {

    const [mobileOpen, setMobileOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    return (

        <>
            <IconButton
                size="medium"
                onClick={handleDrawerToggle}
                color="inherit"
            >
                <FontAwesomeIcon icon={mobileOpen ? faXmark : faBars}  size="xl"/>
            </IconButton>

            <Drawer
                anchor="top"
                variant="temporary"
                open={mobileOpen}
                onTransitionEnd={handleDrawerTransitionEnd}
                onClose={handleDrawerClose}
                transitionDuration={500}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: "100%",  height: "100%"},
                }}>
                <Toolbar />
                <Toolbar />
                <Box m={2} sx={{ display: 'flex', flexDirection: "column", alignItems: "start", width: 200 }}>
                    <List sx={{ p: 0, width: '100%' }}>
                        {
                            pages.map((item, index) =>
                                <ListItem key={index} disablePadding sx={{mt:1}}>
                                    <ListItemButton onClick={handleDrawerClose} component={Link} to={item.value} sx={{ p: 0 }}>
                                        <ListItemText primary={item.label} primaryTypographyProps={{variant: typographyVariant}}/>
                                    </ListItemButton>
                                </ListItem>
                            )
                        }
                        {
                            subMenuList.map((item, index) =>
                                <ListItemWithChildren key={index} label={item.label} data={item.data} close={handleDrawerClose} variant={typographyVariant}/>
                            )
                        }
                    </List>
                </Box>
            </Drawer>
        </>
    );
}

export default MobNavMenu;
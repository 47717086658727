import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, DialogActions, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useContext, useEffect, useState } from 'react';
import imageService from '../../services/imageService';
import userService from '../../services/userService';
import { UserContext } from '../context/Context';
import BootstrapDialog from '../utils/BootstrapDialog';
import { DARKRED, NAVY } from '../utils/Constants';
import FileUploader from '../utils/FileUploader';

const Gallery = () => {

  const admin = useContext(UserContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [user, setUser] = useState([]);
  const [images, setImages] = useState([]);
  const [newImages, setNewImages] = useState([]);

  useEffect(() => {
    (async () => {
      let users = await userService.getAll();
      if (users.length > 0) {
        setUser(users[0]);
        setImages(users[0].imageUrl);
        alignImages(users[0].imageUrl)
      }
    })();

  }, []);

  const openAddImagesDialog = () => {
    setOpenDialog(true);
  }

  const handleGalleryClose = () => {
    setOpenDialog(false);
    setNewImages([]);
  };

  const saveImages = async () => {

    if (newImages.length === 0) {
      alert("You must select an image")
      return
    }
    var uploadedImage = await imageService.uploadImage('img', newImages[0]);
    if (!uploadedImage)
      return

    let imgUrl = uploadedImage.imageUrl;
    const requestPayload = {
      ...user,
      imageUrl: [...user.imageUrl, imgUrl]
    }
    let userResponse = await userService.put(requestPayload);
    setUser(requestPayload);
    setImages(prevImages => [...prevImages, imgUrl]);
  }

  const isMobile = useMediaQuery('(max-width:600px)');

  const RemoveImage = async (img) => {
    var response = prompt("Do you want to delete the image", "yes");
    if (!response || response.toUpperCase() !== "YES") {
      return;
    }
    const requestPayload = {
      ...user,
      imageUrl: user.imageUrl.filter(item => item !== img)
    }
    let userResponse = await userService.put(requestPayload);
    setUser(requestPayload);
    setImages(prevImages => prevImages.filter(item => item !== img));
    console.log("Image removed");
  }

  return (
    <Box width="100%" sx={{ m: { md: 5, xs: 2 } }}>
      {
        admin && <Button variant="contained" color='error' onClick={openAddImagesDialog} sx={{ mb: 2 }}>Add Images</Button>
      }
      <Typography variant="h4" color={DARKRED} fontWeight="bold">Gallery</Typography>
      <ImageList
        variant="quilted"
        cols={4}
        rowHeight={isMobile ? 120 : 350}
        sx={{ borderRadius: 2 }}
      >
        {images.length > 0 ?
          alignImages(images).map((item) => (
            <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
              <img
                {...srcset(item.img, isMobile ? 120 : 350, item.rows, item.cols)}
                alt={item.img}
                loading="lazy"
              />
              {admin && <IconButton
                onClick={() => RemoveImage(item.img)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: "white"
                }}
              >
                <FontAwesomeIcon icon={faClose} />
              </IconButton>}
            </ImageListItem>
          )) :
          <Typography color={NAVY}>Gallery loading...</Typography>
        }
      </ImageList>
      <BootstrapDialog
        onClose={handleGalleryClose}
        open={openDialog}
        id="customDialog"
        scroll="body"
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            saveImages();
            handleGalleryClose();
          },
          sx: { width: { md: "50%" } }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2, backgroundColor: NAVY, color: "white" }} id="customized-dialog-title">
          Add Images
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleGalleryClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: "white"
          }}
        >
          <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
        </IconButton>
        <DialogContent dividers>
          <FileUploader images={newImages} setImages={setNewImages} variant="outlined" maxImages={1} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGalleryClose} sx={{ color: NAVY }}>Cancel</Button>
          <Button type="submit" sx={{ color: NAVY }}>Add</Button>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  );
}

export default Gallery;

const srcset = (image, size, rows = 1, cols = 1) => {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}


const alignImages = (imgs) => {
  let n = imgs.length;
  let result = [];
  const pattern = {
    0: { 0: { rows: 2, cols: 2 }, 3: { cols: 2 } },
    1: { 0: { cols: 2 }, 1: { rows: 2, cols: 2 } },
  };
  const mixedPattern = {
    1: { 0: {cols:2}},
    2: { 0: { cols: 2 }, 1: { cols: 2 } },
    3: { 2: { cols: 2 } },
  };

  if (n > 0) {

    //odd count at the last row 
    let odd = n % 4; 

    //total rows 
    let rows = Math.ceil(n / 4);

    for (let i=0; i<n; i++) {
      //row index
      let rIdx = Math.floor(i / 4);

      if (odd === 0 || rIdx < rows-1) {
        result.push({ img: imgs[i], ...pattern?.[rIdx % 2]?.[i % 4] });
      } else {
        result.push({ img: imgs[i], ...mixedPattern?.[odd]?.[i % 4] });
      }
    }
  }

  return result;
}
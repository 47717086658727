import axios from "axios";
import {v4 as uuid} from 'uuid';
import { baseURL, s3URL } from "../components/utils/Constants";

class ImageClient{

    getSignedUrl = async (request) => {
        try {
            var response = await axios.post(`${baseURL}/signedUrl`, request);
            return response.data.url;
        } catch (error) {
            console.error("Error in get:", error);
            throw error;
        }
    }

    postImageWithSignedUrl = async (signedUrl,file) => {
        try {
                 
            var options = {
                headers: {
                'Content-Type': file.type
                }
            };
            await axios.put(signedUrl, file, options);
            return;
        } catch (error) {
            console.error("Error in get:", error);
            throw error;
        }
    }

    uploadImage = async(projectName, file) => {
        try {
            var imageName = `${projectName}-${uuid()}`
            var imageType = file.type.split('/')[1];
            if (imageType !== 'png' && imageType !== 'jpeg' && imageType !== 'jpg') {
                alert("Only PNG and JPEG images can be uploaded");
                return;
            }

            var imageNameOnS3 = `${imageName}.${imageType}`
            var signedUrlRequest = {
                objectKey: imageNameOnS3,
                s3Action: "putObject",
                contentType: file.type
            };
            var signedUrl = await this.getSignedUrl(signedUrlRequest);
            await this.postImageWithSignedUrl(signedUrl, file);
            return {
                imageUrl:`${s3URL}/${imageNameOnS3}`
            }
        } catch (error) {
            console.error("Error in get:", error);
            throw error;
        }
    }
}


export default new ImageClient();

import { Box, Container, Paper, Stack, Typography } from "@mui/material";
import VisionImg from "../../assets/vision.jpeg";
import { DARKRED, NAVY } from "../utils/Constants";

const Vision = () => {
    return(
        <Box component={Paper} sx={{m:{xs: 2, md: 5}}} width="100%">
            <Stack direction={{ xs: 'column', md: 'row' }}>
                <Container sx={{ my:1, display:{md:'none', xs:'block'}}} >
                    <img width="100%" alt="vision" src={VisionImg} style={{marginTop: 5}}/>
                </Container>
                <Box px={2} pt={2}>
                    <Typography variant="h5" sx={{fontWeight:"bold", textTransform: "uppercase", fontFamily:["Rockwell"], color:DARKRED}}>Vision, Mission & Philosophy</Typography>
                    <Typography variant="h6" mt={2} gutterBottom sx={{fontFamily:["Rockwell"], lineHeight: '1.1', color:DARKRED}}>
                        Our Vision - Inspiring minds, shaping futures!
                    </Typography>
                    <Typography variant="body2" sx={{fontFamily:["Rockwell"], textAlign:'justify', color:NAVY }} >
                        To be a leading construction company in the global market. To become the customer's most preferred choice by attaining excellence in quality and timely completed value added projects. To provide the highest level of service in the construction industry while offering superior craftsmanship to every project, we handle.
                    </Typography>
                    <Typography variant="h6" mt={2} gutterBottom  sx={{fontFamily:["Rockwell"], lineHeight: '1.1', color:DARKRED}}>
                    Our Mission – Driving Impact, Making a Difference Today!
                    </Typography>
                    <Typography variant="body2" sx={{fontFamily:["Rockwell"], textAlign:'justify', color:NAVY }} >
                        An example of a construction company mission statement might be: "To create long-term shareholder value by providing safe and sustainable engineering, construction and service skills to customers for whom quality, efficiency and reliability are critical".
                    </Typography>
                    <Typography variant="h6" mt={2} gutterBottom  sx={{fontFamily:["Rockwell"], lineHeight: '1.1', color:DARKRED}}>
                        Our Philosophy – Pursuing Excellence, Guided by Principles!
                    </Typography>
                    <Typography variant="body2" sx={{fontFamily:["Rockwell"], textAlign:'justify', color:NAVY }} mb={2}>
                        Our buildings will be our legacy. They will speak for us, long after the years gone!
                    </Typography>
                </Box>
                <Container sx={{ mt:2, display:{md:'block', xs:'none'}}}>
                    <img width="500px" alt="vision" src={VisionImg} style={{marginTop: 5}}/>
                </Container>
            </Stack>
        </Box>
    );
}

export default Vision;
import { Link } from "react-router-dom";
import { useState } from "react";
import {
    AppBar,
    Box,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Tab,
    Tabs,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";
import DropdownTab from "./utils/DropdownTab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {
    pages,
    more,
    aboutList,
    NAVY,
    DARKRED,
    WHITE,
    ADMIN_PATH,
} from "./utils/Constants";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MobNavMenu from "./utils/MobNavMenu";
import Logo from "../assets/lycan_logo.png";

const Header = () => {
    const [anchorElUser, setAnchorElUser] = useState(null);
    
    let path =
        window.location.pathname === ADMIN_PATH ? "/" : window.location.pathname;
    const [currentTab, setCurrentTab] = useState(path);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleUserMenuItemClick = () => {
        setCurrentTab(false);
        handleCloseUserMenu();
    };

    const handleTabChange = (e, newValue) => {
        setCurrentTab(newValue);
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: WHITE,
                contrastText: NAVY,
            },
            secondary: {
                main: DARKRED,
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <AppBar
                position="sticky"
                elevation={0}
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    height: { xs: 80, md: "auto" },
                }}
            >
                <Container maxWidth="xl">
                    <Toolbar
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            mx: { xs: 0, md: 3.5 },
                            mt: {xs:1, md:0}
                        }}
                        disableGutters
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                mt: 2,
                                width:{xs:"100%", md:"inherit"}
                            }}
                        >
                            <Box
                                component="a"
                                href="/"
                                sx={{
                                    display: "block"
                                }}
                            >
                                <img height={40} alt="Lycan Constructions" src={Logo} />
                            </Box>

                            <Box sx={{ display: { xs: "block", md: "none" }}}>
                                <MobNavMenu />
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: { xs: "none", md: "flex" },
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                                <Tabs
                                    value={currentTab}
                                    onChange={handleTabChange}
                                    textColor="secondary"
                                    indicatorColor="secondary"
                                    TabIndicatorProps={{ sx: { height: 4 } }}
                                >
                                    {pages.map((page, index) => (
                                        <Tab
                                            label={page.label}
                                            value={page.value}
                                            key={index}
                                            to={page.value}
                                            component={Link}
                                        />
                                    ))}
                                    <DropdownTab
                                        label="About"
                                        value="about"
                                        data={aboutList}
                                        onChange={handleTabChange}
                                        currentTab={currentTab}
                                    />
                                </Tabs>
                            </Box>

                            <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
                                <Tooltip title="More">
                                    <IconButton
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        sx={{ ml: 2 }}
                                        onClick={handleOpenUserMenu}
                                    >
                                        <FontAwesomeIcon icon={faBars} />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: "45px" }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {more.map((item, index) => (
                                        <MenuItem
                                            key={index}
                                            onClick={handleUserMenuItemClick}
                                            component={Link}
                                            to={item.value}
                                        >
                                            <Typography
                                                color={NAVY}
                                                sx={{
                                                    "&:hover": { color: DARKRED },
                                                }}
                                                variant="button"
                                            >
                                                {item.label}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </ThemeProvider>
    );
};

export default Header;

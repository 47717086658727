import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Drawer, IconButton, List, ListItem, ListItemButton, TextField, Toolbar, Tooltip, Typography } from "@mui/material";
import { contacts, DARKRED, NAVY, social } from "./utils/Constants";
import { faClose, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from "react";
import BootstrapDialog from "./utils/BootstrapDialog";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import emailService from "../services/emailService";
import projectService from "../services/projectService";

const drawerWidth = 55;
const variant = "outlined";

const SideDrawer = () => {

    const [openCallDialog, setOpenCallDialog] = useState(false);
    const [openEnquiryDialog, setOpenEnquiryDialog] = useState(false);
    const [projectnames, setProjectnames] = useState([]);

    const handleCallDialogClose = () => {
        setOpenCallDialog(false);
    };

    const handleCallBtnClick = () => {
        setOpenCallDialog(true);
    }

    const handleEnquiryDialogClose = () => {
        setOpenEnquiryDialog(false);
    };

    const handleEnquiryBtnClick = () => {
        setOpenEnquiryDialog(true);
    }

    const handleEnquirySubmit = async(event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());

        if (!validateEmail(formJson.Email)) {
            alert('Invalid email address');
            return;
        }

        if (!validateNumber(formJson.Mobile)) {
            alert('Invalid mobile number');
            return;
        }

        let response = await emailService.sendMail({"subject":"Enquiry from "+formJson.Name, "body": formJson});
        console.log("Enquiry status:",response.status);
        handleEnquiryDialogClose();
    }

    const validateEmail = (mail) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(mail);
    };

    const validateNumber = (number) => {
        const mobileRegex = /^[0-9]{10,}$/;
        return mobileRegex.test(number);
    };

    useEffect(()=>{
        (async ()=>{
            let projects = await projectService.getAll('live');
            setProjectnames(projects.map(item=>item.projectName));
        })()
    }, []);

    return (
        <>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    display: { xs: 'none', md: 'flex' },
                }}
            >
                <Toolbar sx={{ py: 2 }} />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        {contacts.map((item, index) => (
                            <ListItem key={index} disablePadding>
                                <Tooltip title={item.label} placement="right">
                                    <ListItemButton href={item.value} target="_blank">
                                        <FontAwesomeIcon icon={item.icon} size="xl" color={item.color} />
                                    </ListItemButton>
                                </Tooltip>
                            </ListItem>
                        ))}
                        <ListItem disablePadding>
                            <Tooltip title="Enquire now" placement="right">
                                <ListItemButton onClick={handleEnquiryBtnClick}>
                                    <FontAwesomeIcon icon={faEnvelope} size="lg" color="#D14836"  />
                                </ListItemButton>
                            </Tooltip>
                        </ListItem>
                        <ListItem disablePadding>
                            <Tooltip title="Call Now" placement="right">
                                <ListItemButton onClick={handleCallBtnClick}>
                                    <FontAwesomeIcon icon={faPhone} size="lg" color="#1cc95d" />
                                </ListItemButton>
                            </Tooltip>
                        </ListItem>
                        <Divider sx={{ my: 1 }} />
                        {social.map((item, index) => (
                            <ListItem key={index} disablePadding>
                                <Tooltip title={item.label} placement="right">
                                    <ListItemButton href={item.value} target="_blank" >
                                        <FontAwesomeIcon icon={item.icon} size="xl" color={item.color}/>
                                    </ListItemButton>
                                </Tooltip>
                            </ListItem>
                        ))}
                    </List>
                </Box>

            </Drawer>

            {/* mobile */}
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'flex', md: 'none' },
                }}
                anchor="bottom"
            >
                <Box sx={{ backgroundColor: DARKRED }}>
                    <List sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <ListItem sx={{ width: "40%", borderRight: "1px solid white" }} onClick={handleEnquiryBtnClick}>
                            <Typography ml="10%" color="white" variant="h6">Enquire now</Typography>
                        </ListItem>
                        <ListItem sx={{ width: "40%", borderRight: "1px solid white" }} component="a" href="tel:+919176652155">
                            <Typography ml="15%" color="white" variant="h6">Call now</Typography>
                        </ListItem>
                        <ListItem sx={{ width: "20%" }} disableGutters>
                            <a style={{ marginLeft:"20%",backgroundColor: 'green', padding: '8px', borderRadius: '10%' }} href="https://api.whatsapp.com/send?phone=+919176652155&text=Hi,%20I%27m%20interested%20in%20Lycan%20Constructions%20Project.%20Please%20get%20in%20touch%20with%20me.">
                                <FontAwesomeIcon icon={faWhatsapp} color="white" size="2x" />
                            </a>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>

            <BootstrapDialog
                id="callDialog"
                onClose={handleCallDialogClose}
                open={openCallDialog}
            >
                <DialogTitle sx={{ m: 0, p: 2, backgroundColor: DARKRED, color: "white" }} textAlign="center">
                    Call Now
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCallDialogClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: "white"
                    }}
                >
                    <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Mobile: +919176652155
                    </Typography>
                </DialogContent>
            </BootstrapDialog>

            <BootstrapDialog
                onClose={handleEnquiryDialogClose}
                open={openEnquiryDialog}
                id="enquiryDialog"
                scroll="body"
                PaperProps={{
                    component: 'form',
                    onSubmit: handleEnquirySubmit,
                }}
                sx={{'& .MuiTextField-root': { m: 1, width: '96%', backgroundColor: "white" },
                     '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                                borderColor: NAVY,
                            },
                        },
                      '& label.Mui-focused': { color: NAVY, }
                    }}
            >
                <DialogTitle sx={{ m: 0, p: 2, backgroundColor: DARKRED, color: "white" }} id="customized-dialog-title" textAlign="center">
                    Enquire Now
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleEnquiryDialogClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: "white"
                    }}
                >
                    <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
                </IconButton>
                <DialogContent dividers>

                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="Name"
                        label="Name"
                        type="name"
                        fullWidth
                        variant={variant}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="email"
                        name="Email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant={variant}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="mobile"
                        name="Mobile"
                        label="Mobile Number"
                        type="tel"
                        fullWidth
                        variant={variant}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="site"
                        name="Site Location"
                        label="Site Location"
                        fullWidth
                        variant={variant}
                    />
                    <TextField
                        margin="dense"
                        id="comments"
                        label="Comments"
                        name="Comments"
                        multiline
                        rows={4}
                        placeholder="Comments"
                        fullWidth
                        variant={variant}
                    />
                </DialogContent>
                <DialogActions sx={{justifyContent:"center"}}>
                    <Button onClick={handleEnquiryDialogClose} variant="contained" color="error">Cancel</Button>
                    <Button type="submit" variant="contained" color="error">Submit</Button>
                </DialogActions>
                <DialogContentText p={2} variant="subtitle2">  
                    By submitting this form, you consent to LYCAN CONSTRUCTIONS using your contact details to reach out regarding your interest.
                </DialogContentText>
            </BootstrapDialog>
        </>
    );
}

export default SideDrawer;
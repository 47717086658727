import axios from "axios";
import { baseURL } from "../components/utils/Constants";

class EmailClient{

    sendMail = async (request) => {
        try {
            var response = await axios.post(`${baseURL}/sendMail`, request);
            return response.data;
        } catch (error) {
            console.error("Error in get:", error);
            throw error;
        }
    }
}

export default new EmailClient();